
import {defineComponent, computed, onMounted, ref} from 'vue';
import {useStore} from '@/store'
import BaseSelect from '@/components/ui/BaseSelect.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import BaseError from '@/components/ui/BaseError.vue';
import SearchDropdown from '@/components/search/SearchDropdown.vue';
import MasteringSheet from "@/services/MasteringSheet";
import SideBar from '@/components/development-sheet/SideBar.vue';
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import {DevelopmentSheetMutationTypes} from "@/store/modules/development-sheet/mutation-types";
import {MessageModalMutationTypes} from "@/store/modules/message-modal/mutation-types";
import articles from "@/services/articles";
import router from "@/router";
import BaseCheckbox from '@/components/ui/BaseCheckbox.vue';
import imageService from "@/services/images";
import cross from "@/services/cross";
import Spinner from '@/components/Spinner.vue';
import {ConvertDate} from "@/components/converters/date";

export default defineComponent({
  name: 'SheetWrapper',
  components: {
    BaseCheckbox,
    BaseSelect,
    BaseIcon,
    BaseError,
    SearchDropdown,
    SideBar,
    ModalWrapper,
    Spinner
  },
  setup() {
    const store = useStore();
    const activeTab: { [x: string]: any } = computed(() => store.getters.getActiveTabDevelopmentSheet);

    function clearState() {
      store.commit(DevelopmentSheetMutationTypes.CLEAR_STATE, null)
    }

    function setDefaultData() {
      store.commit(DevelopmentSheetMutationTypes.SET_DEFAULT_DATA, null)
    }

    onMounted(async () => {
      let loggedUser = localStorage.getItem('loggedUser');
      let cid = localStorage.getItem('cid');
      if (loggedUser && cid) {
        await setDefaultData();
        let item = document.getElementById('list-name');
        if (item) item.focus()
      } else {
        await router.push('/login')
      }
    });

    return {activeTab, clearState, setDefaultData}
  },
  data: () => ({
    article: {
      name: '',
      group: '',
      subgroup: '',
      status: 'new_temp_status',
      category: '',
      type: '',
      brandArt: ''
    },
    editedArticle: {} as any,
    crosses: [] as any,
    edited: {} as any,
    selectedBrand: {} as any,
    savedInfo: [] as any,
    savedItem: {} as any,
    newArticle: {
      name: '' as string,
      productGroupId: null as any,
      productGroupDesc: '' as string,
      productSubGroupId: null as any,
      productSubGroupDesc: '' as string,
      productSubSubGroupId: null as any,
      productSubSubGroupDesc: '' as string,
      articleData: {} as object,
      data: [[]] as object[],
    } as any,
    newBrandItem: [
      {
        direction: "CM",
        brand: "string",
        brandId: 0,
        brandArt: "",
        linkHard: true,
        linkStock: false
      }
    ],
    file: null as any,
    isLoading: false,
    errors: [] as Array<any>,
    creatingErrors: [] as Array<any>,
  }),
  computed: {
    convertDate() {
      return (date: any) => ConvertDate(date)
    },
    elementClass() {
      return (id: string) => [
        'article-new__field',
        `article-new__field--${id}`
      ]
    },
    convertErrors() {
      let result = [] as any;
      this.errors.forEach((o: any) => {
        if (o.error === 'cross_exist') {
          let text = `Кросс <span style="color: #4F5058;">${o.brand} (${o.brandArt})</span> уже создан для артикула(ов)`;
          let articles = o.existingArticles.map((i: any) => i.article.name).join(', ');
          text = text + ': ' + articles;
          result.push(text)
        } else if (o.error === 'brand_not_found') {
          let text = `Бренд <span style="color: #4F5058;">${o.brand} (${o.brandArt})</span> не найден`;
          result.push(text)
        }
      });
      return result
    },
    correctEnding() {
      return (n: number) => {
        n = Math.abs(n) % 100; let n1 = n % 10;
        if (n > 10 && n < 20) { return 'артикулов'; }
        if (n1 > 1 && n1 < 5) { return 'артикула'; }
        if (n1 == 1) { return 'артикул'; }
        return 'артикулов';
      }
    },
    getTableExampleLink() {
      return MasteringSheet.getFileListOfArticleExample()
    },
  },
  methods: {
    removeBrand(index: number, subIndex: number) {
      this.newArticle.data[index].splice(subIndex, 1)
      this.removeArticles()
    },
    async parseTable(e: any) {
      let file = e.target.files;
      this.file = file[0];
      let textSelector = document.querySelector('.upload-file__text');
      if (textSelector) textSelector.textContent = this.file.name;
      const formData = new FormData();
      formData.append('name', this.newArticle.name);
      formData.append('productGroupId', this.newArticle.productGroupId);
      formData.append('productSubGroupId', this.newArticle.productSubGroupId);
      formData.append('file', file[0]);
      this.closeModal()
    },
    openModal() {
      let element = document.getElementById(`modal-create-sheet`)!;
      element.style.display = 'flex'
    },
    removeArticles() {
      this.newArticle.data = this.newArticle.data.filter((arrayArticles: any, index: number) => {
        if (this.newArticle.data.length > 1 && arrayArticles.length > 0) {
          return arrayArticles
        }

        if (this.newArticle.data.length === 1) {
          return arrayArticles
        }
      })
    },
    closeModal() {
      this.removeArticles()
      let element = document.getElementById(`modal-create-sheet`)!;
      element.style.display = 'none'
    },
    back () {
      this.$router.go(-1)
    },
    save: async function () {
      if (this.$route.params.id) {
        if (!this.newArticle.data || !this.newArticle.data.length || !this.newArticle.data[0].length || !Object.values(this.newArticle.data[0][0]).length) {
          this.setWrongStyle('brands');
        } else {
          this.isLoading = true;

          try {
            await MasteringSheet.putMasteringSheet({
              sheetId: this.$route.params.id,
              data: [...this.newArticle.data]
            });
            this.isLoading = false;
            this.back()
          } catch (error) {
            this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
            this.isLoading = false;
            if (error.data && error.data.length) this.errors = error.data;
            throw error
          }
        }

      } else if (this.validateSheet(this.newArticle)) {
        this.errors = [];
        if (this.file) {
          const formData = new FormData();
          formData.append('name', this.newArticle.name);
          formData.append('productGroupId', this.newArticle.productGroupId);
          if (this.newArticle.productSubGroupId) {
            formData.append('productSubGroupId', this.newArticle.productSubGroupId);
          }
          formData.append('file', this.file);
          this.isLoading = true;
          try {
            await MasteringSheet.postMasteringSheetFile(formData);
            this.isLoading = false;
            this.back()
          } catch (error) {
            this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
            this.isLoading = false;
            if (error.data && !!error.data.length) this.errors = error.data;
            throw error
          }
        } else {
          if (!this.newArticle.data || !this.newArticle.data.length || !this.newArticle.data[0].length || !Object.values(this.newArticle.data[0][0]).length) {
            this.setWrongStyle('brands');
          } else {
            this.isLoading = true;
            if (!this.newArticle.productSubGroupId) {
              delete this.newArticle.productSubGroupId
              delete this.newArticle.productSubGroupDesc
            }
            if (this.article.category && this.article.category.length || this.article.type && this.article.type.length) {
              this.newArticle.data.forEach((article: any, index: number) => {
                this.newArticle.articleData[index + 1] = {
                  ...( (this.article.type && this.article.type.length) && {salesType: ['', 'EXTERNAL', 'INTERNAL', 'SECONDARY'].indexOf(this.article.type)} ),
                  ...( (this.article.category && this.article.category.length) && {articleCategory: ['', 'FOREIGN', 'LOCAL', 'OTHER'].indexOf(this.article.category)} ),
                }
              })
            }
            try {
              await MasteringSheet.postMasteringSheet(this.newArticle);
              this.isLoading = false;
              this.back()
            } catch (error) {
              this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
              this.isLoading = false;
              if (error.data && error.data.length) this.errors = error.data;
              throw error
            }
          }
        }
      }
    },
    setBrand (brand: any) {
      if (this.newBrandItem[0].brandArt && this.newBrandItem[0].brandArt.length > 0) {
        if (brand.brandId) {
          if (brand[0] === ' ') brand = brand.slice(1);
          if (this.newArticle.data.length && Object.values(this.newArticle.data[this.newArticle.data.length - 1]).length) {
            let tempArticles: any = this.newArticle.data[this.newArticle.data.length - 1];
            tempArticles.push(this.newBrandItem.map(b => {
              return {
                ...b,
                brand: brand.brand,
                direction: brand.direction,
                brandId: brand.brandId
              }
            })[0]);
            this.newBrandItem[0].brandArt = '';
            this.newArticle.data[this.newArticle.data.length - 1] = tempArticles
          } else {
            if (!Object.values(this.newArticle.data[this.newArticle.data.length - 1]).length) {
              this.newArticle.data = this.newArticle.data.slice(0, this.newArticle.data.length - 1)
            }
            this.newArticle.data.push(this.newBrandItem.map(b => {
              return {
                ...b,
                brand: brand.brand,
                direction: brand.direction,
                brandId: brand.brandId
              }
            }));
            this.newBrandItem[0].brandArt = ''
          }
        } else {
          this.setWrongStyle('brands-area')
        }
      } else {
        this.setWrongStyle('article-area')
      }
    },
    changeGroup(group: any) {
      if (group) {
        this.newArticle.productGroupId = group.id;
        this.newArticle.productGroupDesc = group.desc;
        this.resetStyle('element-group')
      } else {
        this.newArticle.productGroupId = null;
        this.newArticle.productGroupDesc = '';
        this.changeSubGroup(null);
        this.changeSubSubGroup(null)
      }
    },
    changeSubGroup(subgroup: any) {
      if (subgroup) {
        this.newArticle.productSubGroupId = subgroup.id;
        this.newArticle.productSubGroupDesc = subgroup.desc
      } else {
        this.newArticle.productSubGroupId = null;
        this.newArticle.productSubGroupDesc = '';
        this.changeSubSubGroup(null)
      }
    },
    changeSubSubGroup(subsubgroup: any) {
      if (subsubgroup) {
        this.newArticle.productSubSubGroupId = subsubgroup.id;
        this.newArticle.productSubSubGroupDesc = subsubgroup.name
      } else {
        this.newArticle.productSubSubGroupId = null;
        this.newArticle.productSubSubGroupDesc = ''
      }
    },
    validateSheet(newArticle: any) {
      if (!newArticle.name || newArticle.name.length < 5) {
        this.setWrongStyle('list-name');
        this.creatingErrors.push('Название листа не заполнено.');
        setTimeout(() => {
          this.creatingErrors = []
        }, 5000)
        if (!newArticle.productGroupId || newArticle.productGroupId === 0) {
          this.setWrongStyle('element-group');
          this.creatingErrors.push('Товарная группа не указана.')
          setTimeout(() => {
            this.creatingErrors = []
          }, 5000)
          if (!this.file && (!this.newArticle.data || !this.newArticle.data.length || !this.newArticle.data[0].length || !Object.values(this.newArticle.data[0][0]).length)) {
            this.creatingErrors.push('Артикулы не добавлены.')
            setTimeout(() => {
              this.creatingErrors = []
            }, 5000)
          }
          return false;
        }
        return false;
      } else if (!newArticle.productGroupId || newArticle.productGroupId === 0) {
        this.setWrongStyle('element-group');
        this.creatingErrors.push('Товарная группа не указана.')
        setTimeout(() => {
          this.creatingErrors = []
        }, 3000)
        if (!this.file && (!this.newArticle.data || !this.newArticle.data.length || !this.newArticle.data[0].length || !Object.values(this.newArticle.data[0][0]).length)) {
          this.creatingErrors.push('Артикулы не добавлены.')
          setTimeout(() => {
            this.creatingErrors = []
          }, 3000)
        }
        return false;
      } else if (!this.file && (!this.newArticle.data || !this.newArticle.data.length || !this.newArticle.data[0].length || !Object.values(this.newArticle.data[0][0]).length)) {
        this.creatingErrors.push('Артикулы не добавлены.')
        setTimeout(() => {
          this.creatingErrors = []
        }, 3000)
        return true
      } else {
        return true
      }
    },
    addArticle() {
      if (this.newArticle.data.length && (Object.values(this.newArticle.data[this.newArticle.data.length - 1]).length)) {
        this.newArticle.data.push([]);
        this.newBrandItem[0].brandArt = '';
        this.openModal()
      }
    },
    fillSavedItem() {
      this.newArticle.name = this.savedItem.name;
      this.savedItem.articles.forEach(async (a: any) => {
        await this.getCrosses(a.articleId)
      })
    },
    async getCrosses(articleId: any) {
      try {
        this.editedArticle = await articles.getArticleById(Number(articleId));
        this.newArticle.sheetId = this.savedItem.id;
        this.newArticle.productGroupId = this.editedArticle.productGroup.id;
        this.newArticle.productSubGroupId = this.editedArticle.productSubGroup && this.editedArticle.productSubGroup.id;
        this.newArticle.productGroupDesc = this.editedArticle.productGroup.desc;
        this.newArticle.productSubGroupDesc = this.editedArticle.productSubGroup && this.editedArticle.productSubGroup.desc;
        this.crosses = await cross.getAllCrosses(String(articleId));
      } catch (error) {
        throw error
      }
    },
    setWrongStyle(elem: string) {
      let item = document.getElementById(elem);
      if (item) item.style.border = '2px solid red'
    },
    resetStyle(elem: string) {
      let item = document.getElementById(elem);
      if (item) item.style.border = ''
    }
  },
  async mounted() {
    if (this.$route.params.id) {
      await MasteringSheet.getAllSheets().then(res => {
        this.savedInfo = res;
        if (this.savedInfo && this.savedInfo.length) {
          this.savedItem = this.savedInfo.find((el: any) => String(el.id) === String(this.$route.params.id))
        }
      });
      if (this.savedItem) this.fillSavedItem()
    }
  },
  // async mounted() {
    // const sheet = await MasteringSheet.getSheetById(this.$route.params.id)
    // this.newArticle.name = sheet.name
    // this.newArticle.data = sheet.articles
    //
    // if (sheet.productGroup) {
    //   this.newArticle.productGroupDesc = sheet.productGroup.desc
    // }
    //
    // if (sheet.productSubGroupId) {
    //   this.newArticle.productSubGroupDesc = sheet.productSubGroup.desc
    // }
  // },
  beforeRouteLeave(to, from, next) {
    this.clearState();
    next()
  }
})
