
import {defineComponent, ref, computed} from 'vue';
import {useStore} from '@/store'
import BaseIcon from '@/components/ui/BaseIcon.vue';
import router from '@/router';
import {DevelopmentSheetMutationTypes} from "@/store/modules/development-sheet/mutation-types";

export default defineComponent({
  name: 'SideBar',
  components: {
    BaseIcon
  },
  setup() {
    const store = useStore();
    const notValue = ref(['BASIC']);
    const sideBar: { [x: string]: any } = computed(() => store.getters.getSideBarDevelopmentSheet);
    const activeTab: { [x: string]: any } = computed(() => store.getters.getActiveTabDevelopmentSheet);

    function setActiveTab(item: object) {
      store.commit(DevelopmentSheetMutationTypes.SET_ACTIVE_TAB, item)
    }

    function getCountTab(tab: any) {
    }

    return {notValue, sideBar, activeTab, setActiveTab, getCountTab}
  }
})
