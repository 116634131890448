import {httpClient} from "@/httpClient";
import axios from "axios";

async function postMasteringSheet(newArticleData: { [x: string]: any }) {
  await httpClient.post(`/${localStorage.getItem('cid')}/mastering-sheet`, newArticleData);
}

async function postMasteringSheetFile(newArticleData: { [x: string]: any }) {
  await httpClient.post(`/${localStorage.getItem('cid')}/mastering-sheet/submit`, newArticleData, {
    headers: {'Content-Type': 'multipart/form-data'}
  }).then(res => res.data);
}

function getSheets(page: number | string, sort = 'asc') {
  return httpClient.get(`/${localStorage.getItem('cid')}/mastering-sheet?page=${page}&size=20&sort=date,${sort}`).then(response => response.data)
}

function getAllSheets() {
  return httpClient.get(`/${localStorage.getItem('cid')}/mastering-sheet?size=1000`).then(response => response.data.content)
}

function getSheetById(id: any) {
  return httpClient.get(`/${localStorage.getItem('cid')}/mastering-sheet/${id}`).then(response => response.data)
}

// async function getSheets() {
//   try {
//     const response = await httpClient.get(`/${localStorage.getItem('cid')}/mastering-sheet`);
//     return convertMasteringSheetResponseData(response.data)
//   } catch (error) {
//     throw error
//   }
// }

function getSheetResult(id: any, pageable: any) {
  return httpClient.get(`/${localStorage.getItem('cid')}/mastering-sheet/${id}/result?page=${pageable.page}&size=${pageable.size}`).then(response => response.data)
}

// function convertMasteringSheetResponseData(response: any) {
//   return response.filter((el: any) => el).map((el: any) => {
//     return {...el, date: ConvertDate(el.date)}
//   })
// }

async function putMasteringSheet(newArticleData: { [x: string]: any }) {
  await httpClient.put(`/${localStorage.getItem('cid')}/mastering-sheet`, newArticleData);
}

async function deleteMasteringSheet(sheetId: any) {
  await httpClient.delete(`/${localStorage.getItem('cid')}/mastering-sheet/${sheetId}`)
}
// function getSheetById(payload: string) {
//   return httpClient.get(`/${localStorage.getItem('cid')}/mastering-sheet/${payload}`).then(response => response.data)
// }

// function addToSheet(payload: object) {
//   return httpClient.put(`/${localStorage.getItem('cid')}/mastering-sheet`, payload).then(response => response.data)
// }

function getFileListOfArticleExample() {
  // Возвращаем ссылку для скачивания архива
  return `${axios.defaults.baseURL}/${localStorage.getItem('cid')}/mastering-sheet/fileExampleListOfArticle`
  // return httpClient.get(`article/${localStorage.getItem('cid')}/archive?type=IMAGE`)
}

export default {
  postMasteringSheet,
  postMasteringSheetFile,
  getSheets,
  getSheetById,
  getSheetResult,
  // putMasteringSheet,
  // addToSheet,
  putMasteringSheet,
  getFileListOfArticleExample,
  deleteMasteringSheet,
  getAllSheets
}
